/// import abstracts
@use "sass:meta";
@use 'abstracts/colors';
@use 'abstracts/sizes';
@use 'abstracts/breakpoints';
@use 'abstracts/variables';
@use 'abstracts/animations';
@use 'abstracts/functions';
@use 'abstracts/mixins';

/// import vendors
@use 'vendors/normalize';
@import '~antd/dist/antd.css';

/// import base
@include meta.load-css('base/base');
@include meta.load-css('base/helpers');

/// import layouts
@include meta.load-css('layouts/form');
@include meta.load-css('layouts/grid');
@include meta.load-css('layouts/header');

/// import components
@include meta.load-css('components/button');
@include meta.load-css('components/form');
@include meta.load-css('components/table');
@include meta.load-css('components/color-picker');
@include meta.load-css('components/material-picker');

/// import r_containers - prefixing with r_ for now, because they are React Containers
/// Will probably be removed, when these styles are moved to components/pages/layouts directories
@include meta.load-css('r_containers/app.scss');
@include meta.load-css('r_containers/login.scss');

/// import r_components - prefixing with r_ for now, because they are React Components
/// Will probably be removed, when these styles are moved to components/pages/layouts directories
@include meta.load-css('r_components/header.scss');
@include meta.load-css('r_components/sidebar.scss');

/// import Ant.design component overrides
@include meta.load-css('Ant/content');
@include meta.load-css('Ant/layout');
@include meta.load-css('Ant/menu');
@include meta.load-css('Ant/sider');
@include meta.load-css('Ant/upload');

/// import pages
@include meta.load-css('pages/log-in');
@include meta.load-css('pages/show-form');
@include meta.load-css('pages/module-form');
@include meta.load-css('pages/module-comments');
@include meta.load-css('pages/module-clicks');
