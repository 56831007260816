@use "../abstracts/colors";

.ant-layout-sider {
  background-color: colors.$sidebar-bg;
  &.ant-layout-sider-votemo {
    background-color: colors.$sidebar-bg;
  }

  .ant-layout-sider-trigger {
    background-color: colors.$body-bg;
    color: colors.$black;
  }
  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      padding-left: 0;
      padding-right: 0;
    }
  }
  & .ant-layout-sider-children {
    padding: 15px;
  }
  .ant-menu {
    background-color: colors.$sidebar-bg;
    &-submenu > .ant-menu {
      background-color: colors.$sidebar-bg;
    }
  }
}