@use "../abstracts/colors";

.ant-layout {
  &.layout-theme-votemo {
    background-color: colors.$sidebar-bg;
  }

  &-content {
    margin-top: 15px;
    background: colors.$sidebar-bg;
    flex: 1 !important;
    .main-content {
      border-top-left-radius: 5px;
    }
  }
}