.center-div {
  width: 30%;
  min-width: 360px;
  margin: 10% auto;
  padding: 20px;
}

.text-center {
  text-align: center;
}

