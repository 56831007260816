@use "../abstracts/variables";

/// Base styles

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


body {
  font-family: variables.$font-stack-text !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0;
  }
}

code {
  font-family: variables.$font-stack-code;
}

#root {
  height: 100%;
}

