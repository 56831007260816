@use "sass:color";
@use "../abstracts/colors";

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    background-color: color.adjust(white, $lightness: -50%);
  }
  100% {
    transform: translateY(0);
    background-color: white;
  }
}

.comment-moderator-name {
  font-weight: bold;
}

.add-comment {
  .field {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    input, textarea {
      width: 100%;
      display: block;
      padding: 0.25rem 1rem;
      font-size: 0.75rem;
      border: none;
      &:focus {
        outline: 0;
      }
    }
    &__comment {
      &.has-error {
        .comment-area::placeholder {
          color: colors.$danger;
        }
      }
      .comment-area {
        transition: height 0.1s ease-in-out;
        padding: 0.75rem 4.5rem 0.75rem 1rem;
        resize: none;
        border-radius: 0.2rem;
      }
      &-container {
        position: relative;
      }
    }
    &__submit {
      position: absolute;
      right: 0.425rem;
      bottom: 0.425rem;
      button {
        font-size: 0.75rem;
        padding: 0.35rem;
        border: none;
        border-radius: 0.2rem;
        color: colors.$white;
        background-color: #111cf0;
        min-width: 2rem;
        &.disabled {
          background-color: lightgrey;
          color: #505151;
        }
        &:focus {
          outline: 0;
        }
      }
    }
    &__char-count {
      font-size: 0.75rem;
    }
  }
}

.comments-wrapper {
  background-color: #ebebeb;
  padding: 1rem;
  max-height: calc(100vh - 220px);
  overflow: auto;

  .comment {
    background-color: white;
    border-radius: .5rem;
    margin-bottom: 1rem;
    padding: .5rem;
    animation: .5s ease-out 0s 1 slideInFromTop;

    &__reply {
      margin-left: 100px;
    }

    header {
      display: flex;
      margin-bottom: 0.5rem;

      .comment__author {
        font-weight: bold;
      }

      .comment__author__moderated {
        display: inline-block;
        margin-left: 0.5rem;
        width: 18px;
        height: 18px;

        img {
          width: 100%;
        }
      }

      .comment__time {
        margin-left: 0.5rem;
        color: #bababb;

        &:before {
          font-weight: 900;
          font-size: 5px;
          content: "\f111";
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }

      .comment__action {
        margin-left: auto;

        button {
          color: blue;
          border: none;
          background-color: transparent;
          font-weight: bold;

          &:focus {
            outline: none;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    &.deleted {
      color: #d0d0d0;

      .comment__author {
        color: #d0d0d0;
      }

      .comment__action {
        button {
          color: #d0d0d0;
        }
      }
    }
  }
}
