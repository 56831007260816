// todo: refactor to styled-components

.vote-presenter {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex: 1;
}

.vote-presenter--heading {
  //background-color: #aaffaa;
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  white-space: normal;
  max-width: 45vw;

  img {
    max-width: 100%;
    max-height: 40vh;
    object-fit: cover;
    background-color: white;
    margin: 0 auto;
  }
}

.vote-presenter--results {
  //background-color: #ffaaaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  flex-shrink: 0;
  max-height: 100%;
  max-width: 40vw;

  h3 {
    margin-top: 2em;
    font-weight: normal;
  }
}

.vote-presenter--loading {

}

.vote-presenter__column {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .vote-presenter--heading {
    flex-grow: 0;
  }

  .vote-presenter--results {
    min-width: 50vw;
    height: 50vh;
  }
}
.vote-presenter {
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 32px;

    .vote-presenter--heading {
      flex-grow: 0;
    }

    .vote-presenter--results {
      min-width: 50vw;
      height: 50vh;
    }
  }

  @media (max-width: 800px) {
    .vote-presenter--results {
      min-width: 80vw;
      height: 50vh;
    }
  }
}
