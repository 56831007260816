@use "sass:color";
$body-bg: white;
$sidebar-bg: #ebebeb;
$purple: #32006d;
$grey-title: #d3d3d3;
$blue-dropdown: #1865ee;
$yellow-dropdown: #ffe153;
$green-live: #00d93d;
$red-offline: #cd0000;
$light-gray: #f8f8f8;
$gray: #a3a3a3;
$white: #fff;
$black: #000;
$btn-text-color: white !default;


$yellow: #ffc540;
$link-color: $purple;
$danger: $red-offline;
$form-field-bg: $light-gray;

/// Brand colors - Might turn into a whole color map.
/// Always add default color at least
$primary: $purple;
$secondary: $yellow;
$colors: (
  'primary': (
    'default': $primary,
    'lighter': color.adjust($primary, $lightness: 10),
    'darker': color.adjust($primary, $lightness: -10),
  ),
  'secondary': (
    'default': $secondary,
    'lighter': color.adjust($secondary, $lightness: 10),
    'darker': color.adjust($secondary, $lightness: -10),
  ),
  'danger': (
    'default': $danger
  )
);
