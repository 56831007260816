@use "../abstracts/colors";

.p__log-in {
  background-color: colors.$sidebar-bg;
  height: 100%;

  .log-in-container {
    width: 70%;
    margin: auto  auto;
    border-radius: 1rem;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    box-shadow: 0 0 1.5rem 0.1rem rgba(0,0,0,0.5);
    max-width: 67.5rem;
  }
  .form {
    padding: 2rem;
    text-align: center;
    background-color: white;
    &-header {
      padding: 2rem;
      &__title {
        font-size: 2.25rem;
      }
    }
  }
  .log-in__image-container {
    overflow: hidden;
  }
  .log-in__bg-image {
    background-image: url(../../img/background_votemo_sm.jpg);
    background-size: cover;
    height: 100%;
  }
}