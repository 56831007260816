@use "sass:color";
@use "../abstracts/colors";
@use "../abstracts/functions";

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  color: colors.$btn-text-color;
  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: functions.transition_default('all');
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &:focus {
    outline: 0;
  }
  @each $color, $map in colors.$colors {
    &-#{$color} {
      background-color: functions.get_color($color);
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(functions.get_color($color), 0.8);
      }
    }
  }
  &-link {
    background-color: colors.$body-bg;
    color: colors.$link-color;
    &:hover {
      color: color.adjust(colors.$link-color, $lightness: -10);
      text-decoration: underline;
    }
  }
  &-sm {
    font-size: 0.75rem;
    border-radius: 0.2rem;
    padding: 0.35rem 0.5rem;
  }
  &-lg {
    font-size: 1.25rem;
    border-radius: 0.35rem;
    padding: 0.75rem 1rem;
  }
}

.primary-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.3rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 14px;
  line-height: 1.5;
  transition: functions.transition_default('all');
  cursor: pointer;
  background-color: colors.$purple;
  color: colors.$btn-text-color;
  margin-bottom: 10px;
}
