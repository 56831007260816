@use "../abstracts/colors";

.color-picker {
  height: 100%;
  .rc-color-picker-wrap {
    height: 100%;
    display: flex;
    cursor: pointer;
    padding: 0.25rem;
    margin-right: 2rem;
    // box-shadow: inset 0 1px 0.2rem rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 0 0 0 rgba(colors.$primary, 0.2);
    &:hover, &:focus {
      border-color: colors.$primary;
    }
    &.rc-color-picker-open {
      border-color: colors.$primary;
      box-shadow: 0 0 0 3px rgba(colors.$primary, 0.2);
      background-color: white;
    }
  }
  &__trigger {
    text-align: center;
    width: 100%;
    padding: 2rem;
    font-weight: bold;
    border-radius: 4px;
    > * {
      display: block;
    }
    &-description {
      font-size: 0.65rem;
      font-weight: normal;
      margin-top: 0.25rem;
    }
  }
}

.rc-color-picker-panel-wrap:last-child {
  height: auto !important;
}