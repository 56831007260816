@use "../abstracts/colors";
@use "../abstracts/sizes";

.header {
  flex-grow: 0;
  height: 4rem;
  min-height: 4rem;
  display: flex;
  padding-top: 1rem;

  .logo {
    flex-grow: 0;
    width: sizes.$main-menu-width;
    text-align: center;
  }

  .user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    padding-right: 2rem;

    div {
      margin-left: 1.5rem;
    }
    &#user-menu {
      .usermenu-button {
        padding: 0;
        text-align: left;
      }
    }

    .user-title {
      padding-right: 15px;
      display: block;
      width: 100%;
      text-align: right;
      color: colors.$gray;

      &:first-letter {
        text-transform:capitalize;
      }
    }
  }
}
