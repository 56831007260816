@use "../abstracts/colors";

.sidebar {
  background-color: colors.$sidebar-bg;
  .quick-create-submenu {
    background-color: colors.$secondary;
    margin-bottom: 1.5rem;
    > .ant-menu.ant-menu-sub {
      background-color: colors.$secondary;
    }
  }
}

