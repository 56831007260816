.module-form {
  .options {
    margin-bottom: 2rem;
    /// Somethings fucky.. Gotta revisit this
    .ant-upload.ant-upload-select-picture-card {
      width: 93px !important;
      height: 93px !important;
      max-width: 93px;
      max-height: 93px;
    }
    .ant-upload.ant-upload-select-picture-card img{
      max-height: 75px;
      max-width: 75px !important;
      width: auto !important;
      height: auto !important;
      overflow: hidden;
    }
    .dynamic-delete-button {
      transition: all 0.25s ease-in;
      &:hover {
        transform: scale(1.2);
      }
    }
    .option__single {
      margin-bottom: 32px;
    }
  }
  .reaction-box {
    position: relative;
  }
  .ant-checkbox {
    position: absolute;
    left: 0;
    top: 0;
  }
  .reaction-image {
    display: block;
    width: 75px;
    height: auto;
    margin-top: -16px;
  }
}
