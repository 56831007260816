@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/sizes";

/// Form elements

label {
  display: block;
  margin-bottom: 0.375rem;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 0.2rem rgba(0, 0, 0, .05);
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  // Something for height
  height: sizes.$form-field-height;
  outline: none;
  padding: 0.625rem 1rem;
  background-color: colors.$form-field-bg;
  width: 100%;
  &:focus {
    border-color: functions.get_color('primary');
  }
}

.ant-row.ant-form-item {
  margin-bottom: 8px;
}
.ant-collapse-item .ant-collapse-header {
  padding: 8px 24px !important;

  & > i.ant-collapse-arrow {
    left: 8px !important;
  }
}
.ant-form-item-label label {
  line-height: 1.6rem;
  margin-bottom: 0;
}
.ant-col .ant-form-item-control {
  line-height: initial;
}
