@use "sass:color";
@use "../abstracts/colors";
@use "../abstracts/functions";

.ant-menu.ant-menu-votemo {
  background-color: colors.$sidebar-bg;
  border-right: none;
  .ant-menu-item {
    font-weight: bold;
    color: colors.$primary;
    &.ant-menu-item-selected {
      color: white;
      background-color: color.adjust(colors.$primary, $lightness: 10);
      &:hover {
        color: white;
      }
    }
  }
  .ant-menu-submenu-title {
    font-weight: bold;
    color: colors.$primary;
    .ant-menu-submenu-arrow {
      &:before,
      &:after {
      background-image: linear-gradient(to right, colors.$primary, colors.$primary);
      }
    }
  }
  &-submenu {
    background-color: colors.$sidebar-bg;
    & > .ant-menu {
      background-color: colors.$sidebar-bg;
    }
  }

  > :first-child {
    margin-top: 0;
  }
  .sidebar-icon-fa {
    min-width: 14px;
    margin-right: 10px;
    & + span {
      transition: functions.transition_ant('opacity'), functions.transition_ant('width');
    }
  }
}
.ant-menu-inline-collapsed {
  .sidebar {
    &-icon-fa {
      & + span {
        display: inline-block;
        max-width: 0;
        opacity: 0;
      }
    }
  }
}

// Tooltip coming from collapsed menu
.ant-menu-inline-collapsed-tooltip {
  .sidebar-icon-fa {
    display: none;
  }
}