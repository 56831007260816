@use "../abstracts/colors";
@use "../abstracts/functions";

@each $key, $val in colors.$colors {
  .text-color-#{$key} {
    color: functions.get_color($key) !important;
  }
}

.d-block {
  display: block;
}

.full-width {
  width: 100%;
}

.fw-bold {
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

.text-center {
  text-align: center;
}