@use "../abstracts/functions";

.ant-layout-content {
  margin-left: 270px;
  transition: functions.transition_ant('margin-left');
}

.app__presenter .ant-layout-content {
  margin-left: 0;
  margin-top: 0;
}

.ant-layout-sider-collapsed + .ant-layout > .ant-layout-content {
  margin-left: 80px;
}
