@use "../abstracts/variables";

.material-picker {
  width: 150px !important;
  height: 150px !important;
  font-family: variables.$font-stack-text !important;
  > div > input {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
    border-bottom-width: 15px !important;
  }
}