@use "../abstracts/colors";

.app {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: colors.$body-bg;
}

.app-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background: colors.$sidebar-bg;
  width: 100%;
}

.main-content {
  flex-grow: 1;
  background-color: colors.$body-bg;
  padding: 1rem 2rem 1rem 1rem;
  height: 100%;
}
