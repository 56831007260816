@use "../abstracts/functions";
@use "../abstracts/mixins";

/// layout styles for forms

form {
  .form-group {
    margin-bottom: 0.5rem;
    &.has-error {
      input, textarea {
        border-color: functions.get_color('danger');
        box-shadow: inset 0 1px 0.2rem rgba(functions.get_color('danger'), 0.1);
        @include mixins.animate('shake');
      }
    }
  }
}