@use "sass:string";
@use "breakpoints";
@use "functions";

/// A mixin for targeting a breakpoint
///
/// @example: body background green on only 'md' breakpoint
/// body {
///   @include breakpoint('md', true) {
///     background: green;
///   }
/// }
///
/// @param: {String} $breakpoint - the breakpoint
/// @param: {Boolean} $only - whether to target only a specific range
@mixin breakpoint($breakpoint, $only: false) {
  @if($only) {
    @media (min-width: functions.get_breakpoint($breakpoint)) and (max-width: functions.map-get-next(breakpoints.$breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: functions.get_breakpoint($breakpoint)) {
      @content;
    }
  }
}


/// A mixin to add animation
/// Available animations can be seen in the _animations.scss file
///
/// @example: animate a div to shake
/// div {
///   @include animate('shake');
/// }
@mixin animate($animation, $perspective: 1000px) {
  animation: string.unquote($animation) 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: $perspective;
}